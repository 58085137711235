/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual
// application logic in a relevant structure within app/javascript and only use
// these pack files to reference that code so it'll be compiled.
//
// To reference this file, add
// <%= javascript_packs_with_chunks_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js';
import 'regenerator-runtime/runtime';

import 'bootstrap-sass';
import '../stylesheets/application.scss';
import 'whatwg-fetch';

import ReactGA from 'react-ga';

// Allows using window.Modernizr
import 'shared/vendor/modernizr-custom';

// Needed for sign out actions interestingly enough
// https://github.com/rails/jquery-ujs#installation-using-rails-and-webpacker
import {} from 'jquery-ujs';

// Copied from vanilla Rails 6 project
require('channels');

// Make images in app/javascript/images available for use in asset_pack_path
// (i.e., in Ruby code/templates).
require.context('../images', false, /\.(png|jpg|svg|gif)$/);

if (window.IH && window.IH.googleAnalyticsKey) {
  ReactGA.initialize(window.IH.googleAnalyticsKey);
}
